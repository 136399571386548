'use client';

import React, { useEffect } from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';

import {
    HeroSection,
    UseCaseWithVideoSection
} from '@/components/ClientHomeComponents';
import {
    CTASectionPreset,
    FAQSectionPreset,
    FeatureGridSectionPreset,
    PricingSectionPreset,
    ProblemStatementSectionPreset,
    TestimonialsSectionPreset,
    SolutionStatementSectionPreset
} from './ClientHomePresets';
import { ClassNameKeys, MediaItem, MediaItemProps } from "@/types/homepage";
import { __, TranslationOptions } from '@/utils/translationUtils';

export function ClientHome() {
    const { data: session } = useSession();
    const router = useRouter();

    useEffect(() => {
        if (session) {
            router.push('/s/notes');
        }
    }, [session, router]);

    const translationOptions: TranslationOptions = { source: 'core' };

    const useCases = [
        {
            title: __("Capture Ideas Effortlessly", translationOptions),
            description: __("Transform your thoughts into text instantly with our powerful speech-to-text feature. Never let a brilliant idea slip away again.", translationOptions),
            videoSrc: "/videos/capture-effortlessly.mp4",
            placeholderSrc: "/videos/capture-effortlessly.png",
        },
        {
            title: __("Structure Meeting Notes", translationOptions),
            description: __("Convert your raw meeting notes into a well-organized format automatically. Save time and increase productivity with AI-powered note structuring.", translationOptions),
            videoSrc: "/videos/meeting-notes.mp4",
            placeholderSrc: "/videos/meeting-notes.png",
        },
        {
            title: __("Translate Conversations in Real-Time", translationOptions),
            description: __("Break language barriers with our conversation mode. Communicate effortlessly in foreign languages, perfect for travel or international business.", translationOptions),
            videoSrc: "/videos/ask-directions-in-the-street.mp4",
            placeholderSrc: "/videos/ask-directions-in-the-street.png",
        },
        {
            title: __("Convert Notes to Recipes", translationOptions),
            description: __("Turn your culinary notes into structured recipes instantly. Perfect for chefs, food bloggers, or anyone who loves cooking.", translationOptions),
            videoSrc: "/videos/moms-cheesecake-recipe.mp4",
            placeholderSrc: "/videos/moms-cheesecake-recipe.png",
        },
    ];
    const heroMedia: MediaItemProps = {
        type: 'video',
        src: '/videos/capture-effortlessly.mp4',
        placeholderSrc: '/videos/capture-effortlessly.png'
    };

    const dynamicPhrases = [
        __("CREATE AI PROMPTS", translationOptions),
        __("GENERATE PROMPTS", translationOptions),
        __("EXPLAIN BUGS", translationOptions),
        __("DEFINE FEATURES", translationOptions),
        __("CAPTURE IDEAS", translationOptions),
        __("DRAFT PAPERS", translationOptions),
        __("PLAN DESIGNS", translationOptions),
        __("RECORD PATIENT NOTES", translationOptions),
        __("WRITE RECIPES", translationOptions),
        __("CREATE TO-DO LISTS", translationOptions),
        __("DOCUMENT ALGORITHMS", translationOptions),
        __("COMPOSE ARTICLES", translationOptions),
        __("CREATE THESES", translationOptions),
        __("OUTLINE LESSONS", translationOptions),
        __("DRAFT DOCUMENTS", translationOptions),
        __("BRAINSTORM STRATEGIES", translationOptions),
        __("DOCUMENT STORIES", translationOptions),
        __("CRAFT ROADMAPS", translationOptions),
        __("WRITE EMAILS", translationOptions),
        __("DESIGN PROMPTS", translationOptions),
        __("PROTOTYPE IDEAS", translationOptions),
        __("OUTLINE LAUNCHES", translationOptions),
        __("BRAINSTORM MODELS", translationOptions),
        __("DRAFT PITCHES", translationOptions),
        __("CREATE PERSONAS", translationOptions),
        __("WRITE STORIES", translationOptions),
        __("CRAFT BLOGS", translationOptions),
        __("OUTLINE NOVELS", translationOptions),
        __("CREATE CHARACTERS", translationOptions),
        __("BRAINSTORM PLOTS", translationOptions),
        __("DRAFT DIALOGUES", translationOptions),
        __("COMPOSE POETRY", translationOptions),
        __("WRITE COPY", translationOptions),
        __("DOCUMENT CONCEPTS", translationOptions),
        __("CAPTURE MEMORIES", translationOptions),
        __("DICTATE NOTES", translationOptions),
        __("LOG OBSERVATIONS", translationOptions),
        __("BRAINSTORM STARTUPS", translationOptions),
        __("DRAFT REPORTS", translationOptions),
        __("PRACTICE PRONUNCIATION", translationOptions),
        __("RECORD STORIES", translationOptions),
        __("OUTLINE SPECS", translationOptions),
        __("DOCUMENT FINDINGS", translationOptions),
        __("TAKE NOTES", translationOptions),
        __("PREPARE LESSONS", translationOptions),
        __("DRAFT WILLS", translationOptions),
        __("OUTLINE CHAPTERS", translationOptions)
    ];
    
    return (
        <div
            className="min-h-screen bg-gradient-to-br from-purple-50 to-indigo-50 dark:from-gray-900 dark:to-indigo-950 transition-all duration-500">
            <HeroSection
                preDynamic={__("NO MORE TYPING.", translationOptions)}
                dynamicPhrases={dynamicPhrases}
                postDynamic={__("WITH YOUR VOICE", translationOptions)}
                ctaText={__("Get Started", translationOptions)}
                ctaLink="/pricing"
                media={heroMedia}
                classNames={{
                    [ClassNameKeys.Heading]: 'text-shadow-lg',
                    [ClassNameKeys.Text]: 'text-shadow',
                    [ClassNameKeys.Button]: 'shadow-lg hover:shadow-xl transition-shadow duration-300'
                }}
            />

            <ProblemStatementSectionPreset />

            <SolutionStatementSectionPreset />

            <FeatureGridSectionPreset />

            {/* Use Cases */}
            <UseCaseWithVideoSection useCases={useCases} />

            {/* Social Proof */}
            <TestimonialsSectionPreset />

            {/* Pricing and Value Proposition */}
            <PricingSectionPreset />

            {/* FAQSection */}
            <FAQSectionPreset />

            {/* Call to Action */}
            <CTASectionPreset />

            <footer className="text-center text-gray-600 dark:text-gray-400 mt-24">
                <p className="text-lg">{__("Empowering thinkers, one voice at a time.", translationOptions)}</p>
            </footer>
        </div>
    );
}